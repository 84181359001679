<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix" style="margin-bottom: 20px">
      <h3 class="inBlock">资讯列表</h3>
    </div>
    <div slot="header" class="clearfix">
      <router-link to="addnews">
        <el-button type="primary" plain size="small"> +添加资讯 </el-button>
      </router-link>
      <div class="tiaojian">
        <div class="left">
          <strong>资讯标题: </strong
          ><el-input
            v-model="formdata.keyword"
            placeholder="请输入标题关键词以筛选"
          ></el-input>
        </div>
        <span class="shaixuan shou" @click="filterList">筛选</span>
      </div>
    </div>

    <el-table :default-sort = "{prop: 'sort', order: 'descending'}" :data="tableData" height="500" border style="width: 100%">
      <el-table-column label="ID" prop="id"> </el-table-column>
      <el-table-column label="排序" prop="sort" width="80" sortable> </el-table-column>
      <el-table-column label="标题" prop="title"> </el-table-column>
      <el-table-column label="封面图片" prop="thumbnail">
        <template slot-scope="scope">
          <img style="width: 50px" :src="imgurl + scope.row.thumbnail" alt="" />
        </template>
      </el-table-column>
      <!--    <el-table-column
			label="浏览次数"
			prop="desc">
			</el-table-column> -->
      <el-table-column label="添加时间" prop="">
        <template slot-scope="scope">
          {{ scope.row.create_time | datefilter }}
          <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
						<el-button type="text" size="small">编辑</el-button> -->
        </template>
      </el-table-column>
      <el-table-column label="资讯类型">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? "普通资讯" : "使用手册" }}
          <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
					<el-button type="text" size="small">编辑</el-button> -->
        </template>
      </el-table-column>
      <el-table-column label="总阅读量">
        <template slot-scope="scope">
          {{ scope.row.views + scope.row.invented_views}}
        </template>
      </el-table-column>
      <el-table-column label="实际阅读量" prop="views"> </el-table-column>
      <el-table-column label="虚拟阅读量" prop="invented_views"> </el-table-column>
      <el-table-column label="分类" prop="cat_name"> </el-table-column>
      <el-table-column label="状态" prop="desc">
        <template slot-scope="scope">
          <el-switch
            :disabled="scope.row.verify_status != 1 ? true : false"
            style="margin-right: 10px"
            @change="change(scope.row)"
            v-model="scope.row.status == 1 ? true : false"
          >
          </el-switch>
          <span :style="scope.row.status == 1 ? 'color:blue' : 'color:red'">
            {{ scope.row.status == 1 ? "显示" : "隐藏" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="desc">
        <template slot-scope="scope">
          <span :class="'color' + scope.row.verify_status"
            >{{ scope.row.verify_status | verify_status }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="230">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.verify_status != 1"
            @click="shenhe_c(scope.row.id)"
            type="text"
            size="small"
            >审核</el-button
          >
          <el-button @click="handleClick(scope.row.id)" type="text" size="small"
            >查看</el-button
          >
          <el-button type="text" size="small"
            ><router-link
              style="color: #409eff"
              :to="{
                path: '/newsManage/addnews',
                query: { row: JSON.stringify(scope.row) },
              }"
              >编辑</router-link
            ></el-button
          >
          <el-button
            @click="newsDelete_click(scope.row.id)"
            type="text"
            size="small"
            >删除</el-button
          >
          <el-button
            type="text"
            size="small"
            >
            <router-link
                style="color: #409eff"
                :to="{
                path: '/newsManage/manageTalk',
                query: { id: scope.row.id },
              }"
            >评价管理</router-link>
            </el-button>

        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <span class="demonstration">大于 7 页时的效果</span> -->
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="审核" :visible.sync="shenhe" width="30%">
      <div style="margin-bottom: 10px">
        <el-radio v-model="verify_status" :label="1">通过</el-radio>
        <el-radio v-model="verify_status" :label="2">拒绝</el-radio>
      </div>
      <el-input type="textarea" v-model="reason_rejection"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shenhe = false">取 消</el-button>
        <el-button type="primary" @click="queding">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="资讯详情" :visible.sync="dialogVisible" width="30%">
      <!-- <div style="text-align: center; font-weight: 700; line-height: 40px;">{{name}}</div> -->
      <div style="text-align: center; color: #333333">{{ title }}</div>
      <div style="color: #666; font-size: 12px; text-align: center">
        {{ create_time }}
      </div>
      <div
        class="con"
        style="margin-top: 20px; line-height: 30px;"
        v-html="cont"
      ></div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
/***
 *                    .::::.
 *                  .::::::::.
 *                 :::::::::::
 *             ..:::::::::::'
 *           '::::::::::::'
 *             .::::::::::
 *        '::::::::::::::..
 *             ..::::::::::::.
 *           ``::::::::::::::::
 *            ::::``:::::::::'        .:::.
 *           ::::'   ':::::'       .::::::::.
 *         .::::'      ::::     .:::::::'::::.
 *        .:::'       :::::  .:::::::::' ':::::.
 *       .::'        :::::.:::::::::'      ':::::.
 *      .::'         ::::::::::::::'         ``::::.
 *  ...:::           ::::::::::::'              ``::.
 * ```` ':.          ':::::::::'                  ::::..
 *                    '.:::::'                    ':'````..
 */
import {
  NewsList,
  CourseDetails,
  newsDelete,
  newsChange,
  newsCheck,
} from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      cont: "",
      name: "",
      title: "",
      create_time: "",
      shenhe: false,
      reason_rejection: "",
      verify_status: 1,
      total: 0,
      id: "",
      imgurl: "",
      formdata: {
        page: 1,
        keyword: "",
      },
    };
  },
  filters: {
    verify_status(val) {
      let verify_status = "";
      switch (val) {
        case 0:
          verify_status = "未审核";
          break;
        case 1:
          verify_status = "审核通过";
          break;
        case 2:
          verify_status = "审核拒绝";
          break;
      }
      return verify_status;
    },
  },
  methods: {
    shenhe_c(e) {
      this.shenhe = true;
      this.id = e;
    },
    queding() {
      this.newsCheck(this.id, this.verify_status, this.reason_rejection);
    },
    async newsCheck(id, verify_status, reason_rejection) {
      const { data } = await newsCheck({
        id: id,
        verify_status: verify_status,
        reason_rejection: reason_rejection,
      });
      console.log(data);
      if (data.code != 200) return this.$message.error(data.data);
      this.shenhe = false;
      this.getlist();
    },
    change(row) {
      // newsChange
      console.log(row);
      if (row.status == 1) {
        row.status = 2;
      } else {
        row.status = 1;
      }
      this.newsChange(row.id, row.status);
    },
    handleClick(id) {
      this.courseDetails(id);
      this.dialogVisible = true;
    },
    newsDelete_click(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.news_Delete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async newsChange(id, status) {
      const { data } = await newsChange({ id: id, status: status });
      console.log(data);
      if (data.code != 200) {
        // if(row.status==1){
        // 	row.status=2
        // }else{
        // 	row.status=1
        // }
        return this.$message.error(data.data);
      }
      this.getlist();
      // this.tableData = data.data.goods_list.data;
    },
    async getlist() {
      const { data } = await NewsList(this.formdata);
      console.log(data);
      if (data.code != 200) return this.$message.error(data.data);
      this.tableData = data.data.goods_list.data;
      this.total = data.data.goods_list.total;
    },
    handleCurrentChange(val) {
      this.formdata.page = val;
      this.getlist();
    },
    async courseDetails(id) {
      const { data } = await CourseDetails({ id: id });
      console.log(data);
      if (data.code != 200) return this.$message.error(data.data);
      this.cont = data.data.content;
      this.name = data.data.name;
      this.title = data.data.title;
      this.create_time = data.data.create_time;
      // this.tableData = data.data.goods_list.data;
    },
    async news_Delete(id) {
      const { data } = await newsDelete({ id: id });
      console.log(data);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message({
        type: "success",
        message: "删除成功!",
      });
      this.getlist();
      // this.tableData = data.data.goods_list.data;
    },

    filterList() {
      console.log(123);
      this.formdata.page = 1;
      this.getlist();
    },
    demo() {
      let time = "2020-1-04";
      let time2 = time.replaceAll('-', '/')
      console.log(time2);
    },
  },
  created() {
    this.demo();
  },
  mounted() {
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
    this.getlist();
  },
};
</script>
<style lang="less">
.con img {
  width: 100%;
}
.color0 {
  color: red;
}
.color1 {
  color: royalblue;
}
.color2 {
  color: #da0618;
}
.tiaojian {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #edeff0;
  position: relative;
  .left {
    strong {
      font-weight: normal;
      font-size: 14px;
      color: #606266;
      padding-top: 10px;
      margin-right: 2px;
    }
    .el-input {
      width: 300px;
      margin-right: 20px;
      /deep/.el-input__inner {
        height: 30px;
      }
    }
    .checkboxBox {
      padding-top: 10px;
      margin: 0 0 20px 0;
    }
  }
  .shaixuan {
    background-color: #18bdff;
    color: aliceblue;
    padding: 3px 10px;
    border-radius: 2px;
    position: absolute;
    bottom: 15px;
    left: 400px;
    &:hover {
      background-color: #1abe9c;
    }
  }
}
/deep/.el-dialog__wrapper .el-dialog{
  height: 100%;
  overflow-y: scroll;
}
</style>
